import { processHTML } from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/TelegramForm'
import { useStoreState } from 'easy-peasy'
import React from 'react'
import './styles.scss'
import TestOnDevice from '../TestOnDevice'

export default function TetegramBotPreview() {
  const { content } = useStoreState((state) => state.campaignModule)
  return (
    <div className="w-full" id="ContentPreview">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-lg">Preview</span>
        <div className="flex gap-1 platform-select items-center text-brand_primary font-semibold">
          <TestOnDevice />
        </div>
      </div>

      <div className="preview-screen">
        <div
          style={{
            width: '400px',
            background: 'white',
            minHeight: '236px',
            padding: '16px',
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: content?.content != null ? processHTML.toSemanticHTML(content?.content) : '',
            }}
            className="telegram-preview"
          />
        </div>
      </div>
    </div>
  )
}
